// @flow
import React from 'react'

function About() {
  document.title = "About Us"
  return (
    <div id="content" className="squeeze">
      <div className="row justify-content-start">
        <div className="col">
          <h1 className="font-sniglet">Who are we?</h1>
          <p>
            We are a husband and wife team who foster young children for the state of Georgia.  We started this company with the goal of 
            providing entertaining and educational activities for children and their parents.
          </p>
          <p>
            Our children love to play and learn, but during the lockdown of 2020 it was hard for them.  They were not able to go out and do 
            fun activities at Childrens Museums or parks was hard on them.  Together we came up with fun activities and crafts to keep them 
            busy and to add help them learn new things.
          </p>
          <p>
            Now we want to bring the fun of learning and the joy of playing to your family as well.
          </p>
          <p>
            Thank You everyone for joining us on this journey.
          </p>
          
        </div>
      </div>
      </div>
  );
}

export default About;
