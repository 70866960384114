// @flow
import React from 'react'
import Button from 'react-bootstrap/Button'

function ContactUsSuccess(props) {
  document.title = "Contact Us - Success"
  
  return (
    <div>
      <h1 className="font-sniglet center-box">Thank you!</h1>
      <p className="center-box">Thank you for contacting us, we will respond as soon as we are able.</p>
      <p className="center-box">
        <Button size="lg" className="shop action-button" variant="outline-light" id="return" onClick={()=>{window.location.replace("/");}}>Return to Home</Button>
      </p>
    </div>
  );
}

export default ContactUsSuccess;
