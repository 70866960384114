// @flow
import React from 'react'

import ContactUsSuccess from './success'
import ContactUsForm from './form'
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
function ContactUs(props) {
  document.title = "Contact Us"

  let match = useRouteMatch();


  return (
    

    <div id="content" className="squeeze">

      <div className="row justify-content-start">
        <div className="col">
          <Switch>
            <Route path={`${match.path}/thank-you`}>
              <ContactUsSuccess />
            </Route>
            <Route path={`${match.path}`}>
              <ContactUsForm />
            </Route>
          </Switch>
          
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
