import React from 'react';
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <footer className="fixed-bottom bg-nav">

      <nav className="navbar navbar-expand navbar-dark bg-nav mx-auto">
        <span className="navbar-text copyright">
          Copyright &copy; 2021 BeeyondTheBox.  All rights reserved.
        </span>
        <ul className="navbar-nav mx-r">
          <li className="nav-item active">
            <Link to="/privacy" className="nav-link" >Privacy Policy</Link>
          </li>
          <li className="nav-item active">
            <Link to="/terms" className="nav-link" >Terms of Use</Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer