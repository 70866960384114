// @flow
import React from 'react'
import beeInBox from '../static/bee-in-box.png'
import beeAnimation from '../static/animations/bee-animation.gif'
import beeOutofBox from '../static/animations/beeoutofbox.gif'

function Assets() {
  document.title = "Assets";
  return (
      <div id="content" className="center">
        <div className="row justify-content-start">
          <div className="col">
            <img src={beeInBox} alt="BeeInBox" className="img-fluid float-left img-thumbnail"/>

            <img src={beeAnimation} alt="Bee Animation" className="img-fluid float-left img-thumbnail" />

            <img src={beeOutofBox} alt="Bee Coming out of Box" className="img-fluid float-left img-thumbnail" />
          </div>
        </div>
      </div>
  );
}

export default Assets;
