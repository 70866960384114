// @flow
import React from 'react'

function Terms() {
  document.title = "Terms and Conditions";
  return (
      <div id="content" className="center">
        <div className="row justify-content-start">
          <div className="col">
            <h1 className="font-sniglet">Terms and Conditions</h1>
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col">
            <p>Last updated: February 20, 2021</p>
            <p>
              By accessing this site you agree to not hold Beeyond The Box liable for any damages or losses related to the use of this site and/or services provided therein.
            </p>
            <p>
              You further agree that all images, text, and materials contained on this site are the sole ownership of Beeyond the Box except where attributed or specified otherwise. 
              You may not, except under explicit license, use, distribute, or share the images, text, or materials contained herein for any use non-commercial or commercial
            </p>
          </div>
        </div>
    
      </div>
  );
}

export default Terms;
