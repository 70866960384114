import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
//import App from './App';
import reportWebVitals from './reportWebVitals';
import './custom.scss'
import './index.scss'
import './App.scss';
import Nav from './components/nav'
import Footer from './components/footer'
import Home from './pages/home'
import About from './pages/about'
import Privacy from './pages/privacy'
import Terms from './pages/terms'
import Assets from './pages/assets'
import Unsubscribe from './pages/newsletter/unsubscribe'
import ContactUs from './pages/contactus/contactus'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
//import './App.scss'

ReactDOM.render(
  <Router>
    <Nav/>
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/unsubscribe">
          <Unsubscribe />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/contactus">
          <ContactUs />
        </Route>
        <Route path="/assets">
          <Assets />
        </Route>
        <Route exact path="/" >
          <Home />
        </Route>    
      </Switch>
    <Footer/>
  </Router>
,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
