import React from 'react';
import { Link, NavLink } from "react-router-dom";
import logo from '../static/web-logo.svg'
const Nav = () => {
  return (
    <header>
      <nav className="navbar  navbar-dark bg-nav">
        <Link to="/" className="navbar-brand">
          <img src={logo} width="372px" height="0px" className="logo img-fluid d-inline-block align-top" alt="busyboxclub logo" loading="lazy" />
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <NavLink to="/" className="nav-link"  >Home</NavLink>
            </li>
            <li className="nav-item">
              <a className="nav-link" target="_blank" href="https://shop.beeyondthebox.com" rel="homepage">Shop</a>
            </li>
            {/* <li className="nav-item">
              <NavLink to="#takealook" className="nav-link" >Take a Look</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="#ourstory" className="nav-link" >Our Story</NavLink>
            </li> */}
            {/* <li className="nav-item">
              <NavLink to="/contactus" className="nav-link" >Contact Us</NavLink>
            </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
}


export default Nav