// @flow
import React, { useState } from 'react'
//import { useLocation } from "react-router-dom";
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'

function ContactUsForm(props) {
  document.title = "Contact Us"
  const [validated, setValidated] = useState(false);
  //let location = useLocation();
  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    
    if(form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      setValidated(true);
      event.preventDefault();
      var values= {
        email: form.email.value,
        firstname: form.firstname.value,
        lastname: form.lastname.value,
        type: form.type.value,
        message: form.message.value
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify(values);
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      //const response =
      try{
      await fetch("https://api.beeyondthebox.com/contactus", requestOptions);
      }catch(err){}
      window.location.replace("contactus/thank-you")

    }

  };


  return (
  
 
    <Form noValidate validated={validated} onSubmit={handleSubmit} id="contactus">
      <Form.Row>
            Fill out the form to ask us any questions or leave a comment!
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="6" controlId="firstname">
          <Form.Label>First Name:</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Mary"
          />
          <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="lastname">
          <Form.Label>Last Name:</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Sue"
          />
          <Form.Control.Feedback type="invalid">Required</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="email">
          <Form.Label>Email Address:</Form.Label>
          <Form.Control
            required
            type="email"
            placeholder="marysue@domain.com"
            />
          <Form.Control.Feedback type="invalid">A valid email address is required to receive the newsletter!</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <fieldset>
        <Form.Group as={Row}>
          <Form.Label as="legend" column sm={3}>
            What would you like to contacting us about?
          </Form.Label>
          <Col sm={10}>
            <Form.Check
              type="radio"
              label="I have a question"
              name="type"
              id="form-type-question"
              value="question"
            />
            <Form.Check
              type="radio"
              label="I have feedback"
              name="type"
              id="form-type-feedback"
              value="feedback"
            />
            <Form.Check
              type="radio"
              label="I have a problem"
              name="type"
              id="form-type-problem"
              value="problem"
            />
          </Col>
        </Form.Group>
      </fieldset>
      <Form.Row>
        <Form.Group as={Col} md="12" controlId="message">
          <Form.Label>What would you like to ask/tell us?</Form.Label>
          <Form.Control
            required
            as="textarea"
            rows={10}/>
          <Form.Control.Feedback type="invalid">Please include any questions or comments you have for us.</Form.Control.Feedback>
        </Form.Group>
      </Form.Row>
      <Button type="submit"
              variant="outline-dark"
              className="shop action-button"
              id="contactus-submit"
              form="contactus"
      >Send Query</Button>
    </Form>

  );
}

export default ContactUsForm;
