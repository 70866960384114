// @flow
import React, { useEffect, useState } from 'react'
import {useLocation} from 'react-router-dom'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function Unsubscribe(props){
  const [success ] = useState(false);
  const [errorMessages] = useState("");
  let query = useQuery();

  async function unsubscribe(sid) {
    let resp = await fetch(
      'https://api.thebusyboxclub.com/dev/newsletter/unsubscribe?sid='+sid,
      {
        mode: 'no-cors'
      }
    )
    
    console.log(JSON.stringify(resp))
    // .then(data => {
    //     console.log(data)
    //     if(data.ok) {
    //       console.log("Data ok")

    //       setSuccess(true);
    //       setErrorMessages();
    //     } else {
    //       console.error("Data not ok")
    //       setSuccess(false);
    //       setErrorMessages("The subscriber id was not found.");
    //     }
    //   }
    // ).catch(error => {
    //     console.log("fetch Error: ");
    //     console.log(error)
    //     setSuccess(false);
    //     setErrorMessages(["An error occured while attempting to unsubscribe."]);
    //   }
    // );
  }



  useEffect(()=>{
    unsubscribe(query.get("sid"))
    console.log("iran")

  },[success, query])

  const unsub = <div id="content">
        <div className="row justify-content-start">
          <div className="col">
            <h1 className="font-sniglet">You have been unsubscribed from The Busy Box Club Newsletter.</h1>
            <h1>What this means</h1>
            <ul>
              <li>Your email and information have been deleted from our Newsletter system</li>
              <li>You will not receive anymore emails from our newsletter</li>
              <li>If you wish to start receiving the newsletter again you must resubscribe</li>
            </ul>
          </div>
        </div>
      </div>
    
  const error = <div id="content">
        <div className="row justify-content-start">
          <div className="col">
            <h1 className="font-sniglet">An Error occurred attempting to unsubscribe.</h1>
            <h1>Why did this happen?</h1>
            <h2 className="font-sniglet">{errorMessages}</h2>
            <h1>What this means</h1>
            <ul>
              <li>We were unable to remove your information from our newsletter database</li>
              <li>You can try again in a few minutes</li>
            </ul>
          </div>
        </div>
      </div>

  if(success) {
    console.log("success: " + success)
    return(unsub);
  } else {
    console.log("Success: " + success)
    return (error);
  }  
    
}

export default Unsubscribe;
